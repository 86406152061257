html,
body,
#root {
    height: 100%;
}

body {
    background: #ffffff;
}

header {
    background: #ffffff;
}

/***********************************
* React Select
************************************/
.react-select-container.is-invalid .react-select__control {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.react-select-container .react-select__control {
    border-radius: 0.25rem !important;
}

.react-select-container.is-valid .react-select__control {
    border-color: #255b39;
}

/***********************************
* Common styles
***********************************/

.react-pdf__Document {
    width: 848px;
    margin: 0 auto;
}

.view-data {
    color: #686868;
    font-size: 12px;
    padding-left: 6px;
}

.bg-pattern {
    width: 100%;
}

.container {
    padding: 0 1.5rem !important;
}

.btn-custom-small {
    letter-spacing: 0;
    font-size: 11px;
    padding: 5px 8px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:disabled {
    background-color: #2F4262;
    border-color: #2F4262;
    font-size: 12px;
}

.btn-custom {
    font-size: 12px;
}

.form-control:focus {
    box-shadow: none;
}
.layout {
    position: absolute;
    opacity: .5;
    top: 0;
    left: 0;
    z-index: 1000;
}

.btn-custom-light, .btn-custom-light:hover {
    color: #979797 !important;
    background: transparent;
    border: 1px solid #979797 !important;
}

p {
    font-size: 12px;
    margin-bottom: .2rem;
}

.form-label {
    margin-bottom: 0.2rem;
    color: #2f4262;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .5px;
    padding-left: 2px;
}

.form-control {
    border: 1px solid #979797!important;    
    color: #979797!important;
    font-size: 12px;
}

.react-select__control, .react-select__control--is-focused {
    border: 1px solid #979797 !important;
    color: #979797 !important;
    border-radius: 18px !important;
    font-size: 12px;
    min-height: 32px !important;
}

.react-select__single-value {
    color: #979797 !important;
}

.react-select__indicator {
    padding: 2px !important;
}

.react-select__menu {
    font-size: 12px;
}

.alert-danger {
    color: #fff;
    background-color: #d00920;
    border-color: #d00920;
}

.alert {
    font-size: 12px;
    padding: .5rem;
}

header {
    background: #2f4262;
    color: #ffffff !important;
    padding: 13px 20px 11px 20px;
}

.header-content {
    grid-template-columns: 212px 1fr 100px;
}

.search-container {
    position: relative;
}

.search-container i {
    position: absolute;
    color: #2f4262;
    font-size: 12px;
    left: 17px;
    top: 6px;
    z-index: 10;
}

.search-container input {
    max-width: 310px;
    padding: 3px 52px;
    border: none !important;
}

.search-container .react-select__control {
    padding-left: 25px;
}

.search-container .react-select__menu {
    color: #000;
}

.search-container .react-select__indicators {
    display: none;
}

header .user-name {
    text-align: right;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.1px;
    font-weight: 600;
}

.no-margin {
    padding: 0 !important;
}

/*********************************
* Aside
**********************************/
.aside {
    background-color: #f4f5f7;
    color: #2f4262;
    padding: 44px 17px;
}

.aside ul li {
    display: block;
    width: 100%;
    border-bottom: 1px solid #2f4262;
    margin-bottom: 11px;
    padding: 8px 0;
}

.aside ul li a {
    color: #2f4262;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.75px;
    padding: 0;
    display: grid;
    grid-template-columns: 46px 1fr;
    align-items: center;
}

.aside ul li:hover a, .aside ul li a.active {
    color: #2f4262;
}

.aside ul li a .icon-aside {
    display: inline-block;
    width: 47px;
    text-align: center;
}

.aside ul li:hover a .icon-aside svg, .aside ul li a.active .icon-aside svg {
    fill: #2f4262 !important;
}

.aside ul li a .icon-aside svg {
    height: 18px;
    fill: #fff;
}

.aside ul li a .icon-aside i {
    font-size: 20px;
}

/**********************************
* Modals
**********************************/
.modal-header {
    text-transform: uppercase;
    letter-spacing: .75px;
}

.modal-header .btn-close {
    opacity: 1;
    border: 3px solid red !important;
    width: 5px;
    height: 5px;
    position: relative;
    background: none;
    border-radius: 50%;
}

button.btn-close::before {
    content: "X";
    color: red;
    font-size: 12px;
    position: absolute;
    top: 2px;
    left: 6px;
    font-weight: 800;
}

.modal-error .modal-header {
    background: #d00920;
    color: #fff;
}

.modal-header .h4 {
    font-size: 15px !important;
}

.modal-default .modal-header {
    background: #2f4262;
    color: #fff;
}

/**********************************
* Cards
***********************************/
.card-header {
    background: #2f4262;
    border: 1px solid #2f4262;    
}

.card-header .card-title {
    color: #fff;
    font-size: 12px !important;
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 1px;
}

.card-body .person-name {
    text-transform: uppercase;
    color: #2f4262;
    font-size: 12px;
    margin-bottom: 18px;
}

.canvas .card-body {
    padding: 1.5rem;
    border: 1px solid rgba(0,0,0,.125);
}

.card {
    border: none;
}

.card .body-title {
    color: #2f4262;
    font-size: 12px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    margin-bottom: 20px;
}

/***********************************
* Breadcrumbs
***********************************/
.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #979797;
    content: ">";
}

.breadcrumb-item a {
    text-decoration: none;
    color: #979797 !important;
}
.breadcrumb-item {
    color: #979797 !important;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .75px;
}

nav[aria-label="breadcrumb"] {
    margin-top: 21px;
    padding-left: 25px;
}

/**************************************
* Table
***************************************/
.table thead th {
    color: #2f4262;
    text-transform: uppercase;
    font-size: 12px;
}

.table tbody td {
    color: #686868;
    font-size: 12px;
}

.table tbody td .icon {
    font-size: 16px;
}

.table>:not(caption)>*>* {
    padding: 10px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: #f4f5f7;
    color: #686868;
}

.table>:not(:first-child) {
    border-top: 2px solid #2f4262;
}

.icon {
    padding: 0 5px;
}

.icon svg {
    height: 16px;
}

.icon-delete {
    fill: #c51a1b;
}

.icon-pairing {
    fill: #adc90e;
}

.icon-edit {
    fill: #666666;
}

.btn-plus {
    color: #ffffff;
    text-decoration: none;
    border: 1px solid #ffffff;
    border-radius: 50%;
    text-align: center;
    height: 18px;
    width: 18px;
    display: inline-block;
    position: relative;
}

.btn-icon {
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    font-size: 18px;
}

.btn-plus::before {
content: "+";
position: absolute;
top: 0;
left: 5px;
font-size: 12px;
}

.btn-plus:hover {
color: #ffffff;
}

.entries-table-container {
    display: grid;
    grid-template-columns: 1fr 110px;
}

.entries-table-container table {
    display: block;
}

.entries-table-container table:first-child {
    overflow-x: scroll;
    max-width: 100%;
    overflow-y: hidden;
}

.entries-table-container table tbody td div {
    height: 40px;
    overflow-y: hidden;
    white-space: nowrap;
}