.born-alive-list ul {
    margin: 0;
    padding: 0;
}

.born-alive-list ul li {
    list-style: none;
    margin: 0;
}

.born-alive-list ul li a {

}